import { AnimatePresence, motion } from 'framer-motion';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import Button from '../components/Button';
import Layout, { Section } from '../components/Layout';
import { Seo } from '../components/Seo';
import { Text, Title } from '../components/Text';
import { sprinkles } from '../sprinkles.css';

const PlanTag = ({
	name,
	size = 'small',
}: {
	name: 'Free' | 'Business' | 'Enterprise' | string;
	size?: 'small' | 'medium' | 'large';
}) => {
	const { t } = useTranslation('FeaturesPage');
	if (!name) return null;
	return (
		<Section align="start" width="auto">
			<Text variant="secondary">{t('Plan')}</Text>
			<div
				className={sprinkles({
					borderRadius: 'medium',
					backgroundColor: name === 'Free' ? 'primaryBackground' : 'pinkLight',
					color: name === 'Free' ? 'primary' : 'pink',
					paddingY: size,
					paddingX: { small: 'medium', medium: 'large', large: 'larger' }[size] as
						| 'medium'
						| 'large'
						| 'larger',
					fontSize: size,
				})}
			>
				{name}
			</div>
		</Section>
	);
};

const StatusTag = ({
	name,
	size = 'small',
}: {
	name: 'Ready' | 'Development' | 'Planned' | string;
	size?: 'small' | 'medium' | 'large';
}) => {
	const { t } = useTranslation('FeaturesPage');
	if (!name) return null;
	return (
		<Section width="auto" align="start">
			<Text variant="secondary">{t('Status')}</Text>
			<div
				className={sprinkles({
					borderRadius: 'medium',
					backgroundColor: name === 'Ready' ? 'greenLight' : 'background',
					color: name === 'Ready' ? 'green' : 'textLight',
					paddingY: size,
					paddingX: { small: 'medium', medium: 'large', large: 'larger' }[size] as
						| 'medium'
						| 'large'
						| 'larger',
					fontSize: size,
				})}
			>
				{name}
			</div>
		</Section>
	);
};

const Subfeature = (data: {
	title: string;
	description: string;
	status: string;
	plan: string;
	mockupPath: string;
	essential?: boolean | null;
}) => {
	const { t } = useTranslation('FeaturesPage');
	return (
		<Section
			width="fill"
			align="start"
			direction="horizontal"
			justify="stretch"
			gap="medium"
			className={sprinkles({
				flexWrap: {
					mobile: 'wrap',
					tablet: 'wrap',
					desktopHalf: 'nowrap',
				},
			})}
		>
			<div
				className={sprinkles({
					maxWidth: { mobile: 'fill', tablet: 'fill', desktopHalf: 'mobile' },
					flexGrow: '1',
				})}
			>
				<Section gap="medium">
					<div
						className={sprinkles({
							padding: 'large',
							borderRadius: 'medium',
							backgroundColor: 'primaryBackground',
						})}
					>
						{data.essential != null && (
							<Text strong align="right" variant="secondary">
								#{data.essential ? t('Essential') : t('AddedValue')}
							</Text>
						)}
						<Title level="paragraph">{data.title}</Title>
						<Text variant="primary">{data.description}</Text>
					</div>
					<Section direction="horizontal" justify="between">
						<PlanTag name={data.plan} />
					</Section>
				</Section>
			</div>
			<div
				className={sprinkles({
					flexGrow: '2',
				})}
				style={{ width: '120%' }}
			>
				{React.createElement(
					require(`../icons/mock/features/${data.mockupPath || 'dashboard.svg'}`),
					{
						width: '100%',
						height: 'auto',
					},
				)}
			</div>
		</Section>
	);
};

const Subfeatures = ({
	data,
	parentPlan,
	parentStatus,
}: {
	data: readonly Queries.Subfeature[];
	parentStatus: string;
	parentPlan: string;
}) => {
	const [index, setIndex] = useState(0);
	return (
		<Section width="fill" gap="large">
			{data.length > 1 && (
				<Section width="fill" direction="horizontal" gap="tiny" wrap>
					{data.map((subfeature, i) => (
						<div
							className={sprinkles({
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: 'tiny',
								borderRadius: 'medium',
							})}
							key={i}
						>
							{/* <motion.div
								className={sprinkles({ borderRadius: 'medium' })}
								initial={{ backgroundColor: 'rgba(255, 255, 255, 0)' }}
								whileInView={{
									backgroundColor: [
										'rgba(255, 255, 255, 0)',
										theme.colors.primaryBackground,
										theme.colors.primaryBackground,
										'rgba(255, 255, 255, 0)',
									],
								}}
								transition={{
									delay: 1 * i,
									duration: 1,
									repeat: 1,
									repeatDelay: 10,
									times: [0, 0.3, 0.7, 1],
								}}
							> */}
							<Button
								onClick={() => setIndex(i)}
								variant={index === i ? 'primaryText' : 'secondaryText'}
								size="medium"
							>
								<span className={sprinkles({ fontWeight: 'bold' })}>
									{i + 1}. {subfeature.title}
								</span>
							</Button>
							{/* </motion.div> */}
							{/* {i < data.length - 1 ? (
								<svg width="6" height="6">
									<circle fill={colors.textLight} cx="3" cy="3" r="3"></circle>
								</svg>
							) : null} */}
						</div>
					))}
				</Section>
			)}
			<AnimatePresence exitBeforeEnter>
				{data.map((subfeature, i) => {
					if (i !== index) return null;
					return (
						<motion.div
							style={{ width: '100%' }}
							animate={{ x: 0, opacity: 1 }}
							exit={{ x: -400, opacity: 0 }}
							initial={{ x: 400, opacity: 0 }}
							transition={{ duration: 0.5 }}
							key={i}
						>
							<Subfeature
								title={subfeature.title}
								description={subfeature.description}
								status={subfeature.status || parentStatus}
								plan={subfeature.plan || parentPlan}
								mockupPath={subfeature.mockup}
								essential={subfeature.essential}
							/>
						</motion.div>
					);
				})}
			</AnimatePresence>
		</Section>
	);
};

const FeatureSection = ({ feature }: { feature: Queries.FeaturesSection }) => {
	const { t } = useTranslation('FeaturesPage');
	return (
		<Section width="fill" gap="larger" align="start">
			<Section width="fill" gap="small" align="start">
				<Section width="fill" align="start" gap="none">
					<Title align="left" level="subsection">
						{feature.title}
					</Title>
					{feature.essential != null && (
						<Text strong variant="secondary">
							#{feature.essential ? t('Essential') : t('AddedValue')}
						</Text>
					)}
				</Section>
				<Text variant="primary">{feature.description}</Text>
			</Section>
			{!feature.subfeatures || feature.subfeatures.length < 1 ? (
				<Section width="fill" direction="horizontal" justify="between">
					{feature.plan && <PlanTag name={feature.plan} />}
				</Section>
			) : (
				<Subfeatures
					data={feature.subfeatures}
					parentStatus={feature.status || ''}
					parentPlan={feature.plan || ''}
				/>
			)}
		</Section>
	);
};

const FeaturesPage = ({
	data,
}: {
	data: { features: { edges: [{ node: Queries.FeaturesJson }] } };
}) => {
	const features = data.features.edges[0].node;
	const { t } = useTranslation('FeaturesPage');
	return (
		<Layout>
			<Section width="wide" gap="large">
				<Title align="center" level="title">
					{features.title}
				</Title>
				<Section
					align="start"
					width="fill"
					direction="horizontal"
					gap="large"
					className={sprinkles({
						flexWrap: { mobile: 'wrap', tablet: 'wrap', desktopHalf: 'nowrap' },
					})}
				>
					<Section>
						<Title align="left" level="4">
							{features.titleLeft}
						</Title>
						<Text align="left">{features.descriptionLeft}</Text>
					</Section>
					<div
						className={sprinkles({
							padding: 'large',
							borderRadius: 'medium',
							backgroundColor: 'primaryBackground',
						})}
					>
						<Section align="start">
							{/* <Text align="left">{features.descriptionRight}</Text> */}
							<Title level="4">#{t('Essential')}</Title>
							<Text align="left">{features.descriptionEssential}</Text>
							<Title level="4">#{t('AddedValue')}</Title>
							<Text align="left">{features.descriptionAddedValue}</Text>
						</Section>
					</div>
				</Section>
			</Section>
			<Section width="wide" gap="huge">
				{features.sections.map((feature, i) => (
					<FeatureSection key={i} feature={feature} />
				))}
			</Section>
		</Layout>
	);
};

export default FeaturesPage;

export const Head = () => {
	return <Seo title="Features der konfidal app" />;
};

export const pageQuery = graphql`
	query featuresPageQuery($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		features: allFeaturesJson(filter: { language: { eq: $language } }) {
			edges {
				node {
					title
					tabTitle
					titleLeft
					descriptionRight
					descriptionLeft
					descriptionEssential
					descriptionAddedValue
					language
					sections {
						title
						description
						essential
						plan
						status
						subfeatures {
							title
							description
							mockup
							hashtag
							plan
							status
							essential
						}
					}
				}
			}
		}
	}
`;
